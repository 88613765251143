import {URL_API} from "../WebAddresses";
import {RequestClient} from "../utils/ApiClient";

export function upload(file: any): Promise<StorageUploadResult> {
    let form = new FormData();
    form.append("file", file)

    const client = new RequestClient({
        url: URL_API + "/storage",
        method: "POST",
        body: form,
        parameterType: "MultiPart",
        autoSFOMAuth: true
    })

    return client.execute().then(result => {
        return result.data as { id: any; downloadUrl: any; type: any; }
    })
}

interface StorageUploadResult {
    id: string,
    downloadUrl: string,
    type: string
}