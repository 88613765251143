export default interface PostContentUnitData {
    type : UnitType,
    data : string
}

export const enum UnitType {
    TEXT = "text",
    HEADING1 = "h1",
    HEADING2 = "h2",
    IMAGE_URL = "image-url",
    IMAGE_STORAGE = "image-storage",
    RESOURCE_BANNER_LINEAR = "resource-linear-banner",
    RESOURCE_BANNER_EXTENDED = "resource-extended-banner",
    LINK = "link"
}