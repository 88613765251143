import axios, {AxiosRequestConfig} from "axios";
import qs from "qs";
import {getIdToken} from "../auth/SFOMAuth";

interface RequestConfig {
    url: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    headers?: Map<string, string>
    body?: any,
    parameterType?: "JSON" | "FormParameter" | "MultiPart" | undefined,
    autoSFOMAuth: boolean
}


export class RequestClient {

    private config: RequestConfig;

    private axiosConfig: AxiosRequestConfig;

    constructor(config: RequestConfig) {
        this.config = config
        this.axiosConfig = {
            url: config.url,
            method: config.method,
            headers: {}
        }
        if (config.headers) {
            config.headers.forEach((value: string, key: string) => {
                this.axiosConfig.headers![key] = value
            })

        }

        if (config.parameterType)
            switch (config.parameterType) {
                case "JSON":
                    this.axiosConfig.data = JSON.stringify(config.body)
                    this.axiosConfig.headers!["Content-Type"] = "application/json"
                    break;
                case "FormParameter" :
                    this.axiosConfig.data = qs.stringify(config.body)
                    this.axiosConfig.headers!["Content-Type"] = "application/x-www-form-urlencoded"
                    break;
                case "MultiPart":
                    this.axiosConfig.data = config.body
                    this.axiosConfig.headers!["Content-Type"] = "multipart/form-data"
                    break;
            }
    }

    async execute(): Promise<{ status: number, data: any }> {
        if (this.config.autoSFOMAuth) {
            let idToken = await getIdToken();
            if (!idToken) return Promise.reject(new Error('The ID token is invalid.'))
            this.axiosConfig.headers!["Authorization"] = `Bearer ${idToken}`
        }

        return await axios(this.axiosConfig).then(res => {
            return {
                status: res.status,
                data: res.data
            }
        }).catch(error => {
            throw error;
        })
    }
}