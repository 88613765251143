/*
 * Generated type guards for "ProfileData.ts".
 * WARNING: Do not manually change this file.
 */
import ProfileData from "./ProfileData";

function evaluate(
    isCorrect: boolean,
    varName: string,
    expected: string,
    actual: any
): boolean {
    if (!isCorrect) {
        console.error(
            `${varName} type mismatch, expected: ${expected}, found:`,
            actual
        )
    }
    return isCorrect
}

export function isProfileData(obj: any, argumentName: string = "profileData"): obj is ProfileData {
    return (
        (obj !== null &&
            typeof obj === "object" ||
            typeof obj === "function") &&
        evaluate(typeof obj.uid === "string", `${argumentName}.uid`, "string", obj.uid) &&
        evaluate((typeof obj.introduction === "undefined" ||
            obj.introduction === null ||
            typeof obj.introduction === "string"), `${argumentName}.introduction`, "string | null | undefined", obj.introduction) &&
        evaluate(typeof obj.nickname === "string", `${argumentName}.nickname`, "string", obj.nickname) &&
        evaluate((typeof obj.profileImage === "undefined" ||
            obj.profileImage === null ||
            typeof obj.profileImage === "string"), `${argumentName}.profileImage`, "string | null | undefined", obj.profileImage) &&
        evaluate((typeof obj.profileBackgroundImage === "undefined" ||
            obj.profileBackgroundImage === null ||
            typeof obj.profileBackgroundImage === "string"), `${argumentName}.profileBackgroundImage`, "string | null | undefined", obj.profileBackgroundImage)
    )
}
