import {NavLink} from "react-router-dom";
import styled from "styled-components";

const SideBar = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 1.6rem 0;
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    width: 100%;
    display: block;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  ul > li {
    font-size: 1.6rem;
    padding: 1.6rem 2.4rem;
    width: 100%;
    box-sizing: border-box;
    font-weight: bold;
    color: grey;

    @media screen and (max-width: 768px) {
      display: inline;
    }
  }

  a {
    all: unset;
  }

  .activeSidebarMenu {
    color: black;
  }

  ul > li:hover {
    color: black;
  }
`

export default function DashBoardSideBar() {
    return <SideBar>
        <ul>
            <li>메인</li>
            <li>유저관리</li>
            <li><NavLink to={"/resources/wait"} className={({isActive}) => {
                return isActive ? "activeSidebarMenu" : ""
            }}>자료 검수</NavLink></li>
            <li><NavLink to={"posts"} className={({isActive}) => {
                return isActive ? "activeSidebarMenu" : ""
            }}>포스트 관리</NavLink></li>
            <li>공지사항</li>
        </ul>
    </SideBar>
}
