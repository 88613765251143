import {
    Routes,
    Route,
    Link,
    useNavigate,
    useLocation,
    Navigate,
    Outlet,
} from "react-router-dom"
import AdminUser from "../lib/auth/AdminUser";
import {Dispatch, SetStateAction, useContext} from "react";
import {SFOMAuthContext} from "../lib/auth/SFOMAuthContext";
import {CircularProgress} from "@mui/material";

export default function PrivateRoute({children}: { children: JSX.Element }) {
    const location = useLocation()

    const currentUser = useContext(SFOMAuthContext);

    if (!currentUser.isStateLoaded){
        return <CircularProgress color="inherit" />
    } else if (!currentUser.isSignedIn){
        console.log("required sign in")
        return <Navigate to={"/signin"} state={{from: location}} replace/>;
    }else{
        console.log("verified account in", currentUser)
        return children
    }
}