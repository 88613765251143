import PostContentUnitData from "./PostContentUnitData";
import {TextField} from "@mui/material";
import AdminUser from "../../../lib/auth/AdminUser";
import {useState} from "react";

interface PostContentUnitParam {
    contentUnit: PostContentUnitData,
    onDataChanged: (data: (string)) => void
}

export default function PostContentUnit(param: PostContentUnitParam) {

    const [data, setData] = useState(param.contentUnit.data)

    return <div><TextField
        fullWidth
        id="outlined-multiline-static"
        label={param.contentUnit.type}
        multiline
        onChange={(event) => setData(event.target.value)}
        value={data}
        onBlur={()=> param.onDataChanged(data)}
    /></div>
}