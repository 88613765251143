import "./SignInForm.css"
import {Button, TextField} from "@mui/material";
import {useState} from "react";
import {signIn} from "../../lib/auth/SFOMAuth";
import {useNavigate} from "react-router-dom";

export default function SignInForm() {
    let navigate = useNavigate();

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const onSubmit = function (event: any) {
        event.preventDefault();
        signIn(email, password).then(result => {
            navigate('/')
        })
    }

    const onTextFieldChange = function (event: any, stateSetFunc: (value: string) => void) {
        stateSetFunc(event.target.value)
    }

    return <form className={"signInForm"} onSubmit={onSubmit}>
        <div className={"sign-form"}>
            <TextField
                className={"auth-field"}
                type={"email"}
                label={"이메일"}
                variant={"outlined"}
                margin={"normal"}
                value={email}
                onChange={e => {
                    onTextFieldChange(e, setEmail)
                }}
                fullWidth
            />

            <TextField
                className={"auth-field"}
                type={"password"}
                label={"비밀번호"}
                variant={"outlined"}
                margin={"normal"}
                onChange={e => {
                    onTextFieldChange(e, setPassword)
                }}
                fullWidth
            />

            <Button type="submit" id={"signInButton"} variant="text">로그인</Button>
        </div>
    </form>
}