import {IconButton, Menu, MenuItem} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from '@mui/icons-material/Delete';
import "./PostContentUnitMenu.css"
import {useState} from "react";
import {UnitType} from "./PostContentUnitData";

export default function PostContentUnitMenu({onAddUnit, onRemoveUnit}:
                                                { onAddUnit: (type: UnitType) => void, onRemoveUnit: (() => void) | undefined }) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (type: UnitType) => {
        onAddUnit(type);
        setAnchorEl(null);
    };

    return <div className={"component-post-content-unit-menu"}><IconButton id={"add-button"}
                                                                           className={"unitMenuButton"} aria-label="add"
                                                                           size={"small"}
                                                                           aria-controls={open ? 'basic-menu' : undefined}
                                                                           aria-haspopup="true"
                                                                           aria-expanded={open ? 'true' : undefined}
                                                                           onClick={handleClick}>
        <AddIcon/>
    </IconButton>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'add-button',
            }}>
            <MenuItem onClick={() => handleClose(UnitType.TEXT)}>텍스트</MenuItem>
            <MenuItem onClick={() => handleClose(UnitType.HEADING1)}>제목1</MenuItem>
            <MenuItem onClick={() => handleClose(UnitType.HEADING2)}>제목2</MenuItem>
            <MenuItem onClick={() => handleClose(UnitType.IMAGE_URL)}>이미지(URL 방식)</MenuItem>
            <MenuItem onClick={() => handleClose(UnitType.IMAGE_STORAGE)}>이미지(전용 스토리지 방식)</MenuItem>
            <MenuItem onClick={() => handleClose(UnitType.RESOURCE_BANNER_LINEAR)}>자료(일반 리니어 배너)</MenuItem>
            <MenuItem onClick={() => handleClose(UnitType.RESOURCE_BANNER_EXTENDED)}>자료(상세 배너)</MenuItem>
            <MenuItem onClick={() => handleClose(UnitType.LINK)}>링크</MenuItem>
        </Menu>
        {onRemoveUnit ?
            <IconButton onClick={() => onRemoveUnit()} className={"unitMenuButton"} aria-label="remove" size={"small"}>
                <DeleteIcon/>
            </IconButton>
            : <div>엘레먼트를 추가하여 내용을 시작하십시오.</div>}
    </div>
}