import DashBoardSideBar from "./DashBoardSideBar";
import Header from "./Header";
import {Outlet} from "react-router-dom";
import {LinearProgress} from "@mui/material";
import styled from "styled-components";
import {AppBar} from '@mui/material';

const DashBoardParent = styled.div`
  width: 100%;
  height: 100%;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
`

const DashBoardSideBarContainer = styled.div`
  float: left;
  width: 15rem;
  height: 100%;
  background-color: white;
  display: inline-block;
  border-right: 0.1rem black solid;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    display: block;
  }
`

const ContentContainer = styled.div`
  float: left;
  width: calc(100% - 15rem - 0.1rem);
  height: 100%;
  overflow: auto;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    display: block;
  }
`

export default function DashBoardPage() {
    return <DashBoardParent>
        <Container>
            <DashBoardSideBarContainer>
                <DashBoardSideBar/>
            </DashBoardSideBarContainer>
            <ContentContainer>
                <Outlet/>
            </ContentContainer>
        </Container>
    </DashBoardParent>
}