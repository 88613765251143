import {Fragment, useEffect, useState} from "react";
import {useNavigate as rrdNavigate} from "react-router-dom";
import ResourceWaitData from "../../../data/ResourceWaitData";
import {getResourcesWaits} from "../../../lib/repositories/ResourceRepository";
import ResourcesWaitListItem from "./ResourcesWaitListItem";
import styled from "styled-components";
import {List, ListItemButton} from "@mui/material";

const Parent = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.6rem;
  box-sizing: border-box;
`

export default function ResourcesWaitListPage() {
    let navigate = rrdNavigate();

    const [waits, setWaits] = useState<Array<ResourceWaitData>>([])

    useEffect(() => {
        const historyItemKey = '/resources/wait';
        let historyData = localStorage.getItem(historyItemKey);

        if (historyData) {
            setWaits(JSON.parse(historyData))
            // localStorage.removeItem(historyItemKey);
        }
        getResourcesWaits().then(waits => {
            setWaits(waits)
            localStorage.setItem(historyItemKey, JSON.stringify(waits));
            console.log(waits.length)
        })
    }, [])

    const linkToDetail = (id: string) => {
        navigate("/resources/wait/" + id);
    }

    return <Parent>
        {
            <List>
                {waits.map(wait => {
                    return <ListItemButton key={wait.id} onClick={() => linkToDetail(wait.id!)}>
                        <ResourcesWaitListItem resourceWait={wait}/>
                    </ListItemButton>
                })}
            </List>
        }
    </Parent>
}