import axios from "axios";

export function fileDownload(fileHash: String, fileName: string) {
    axios({
        url: `https://cdn.storeforminecraft.dev/${fileHash}`,
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
    });
}
