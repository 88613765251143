import axios from "axios";
import {URL_API} from "../WebAddresses";
import PostData from "../../data/PostData";
import {getIdToken} from "../auth/SFOMAuth";
import {RequestClient} from "../utils/ApiClient";

export async function getPosts(): Promise<Array<PostData>> {
    let idToken = await getIdToken();
    if (!idToken) return Promise.reject(new Error('The ID token is invalid.'))

    return axios({
        url: URL_API + "/posts",
        method: 'GET',
        headers: {
            Authorization: `Bearer ${idToken}`
        }
    }).then((response) => {
        return response.data.map((post: any) => {
            return convertToPost(post);
        });
    });
}

export async function getPost(id: string): Promise<PostData> {
    let idToken = await getIdToken();
    if (!idToken) return Promise.reject(new Error('The ID token is invalid.'))

    return axios({
        url: URL_API + `/posts/${id}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log(response.data)
        return convertToPost(response.data)
    }).catch(error => {
        console.log(error)
        throw error
    });
}

export async function setPost(post: PostData, modified : Boolean): Promise<string> {
    let idToken = await getIdToken();
    if (!idToken) return Promise.reject(new Error('The ID token is invalid.'))

    let requestPost: any = {...post}
    requestPost.translatedBodies = Object.fromEntries(post.translatedBodies!.entries())
    requestPost.translatedTitles = Object.fromEntries(post.translatedTitles!.entries())

    return (await new RequestClient({
        url: `${URL_API}/posts/${requestPost.id ?? ""}`,
        method: modified ? "PUT" : "POST",
        body: requestPost,
        parameterType: 'JSON',
        autoSFOMAuth: true
    }).execute()).data;
}

export async function deletePost(post: PostData): Promise<boolean> {
    if (!post.id) return false

    return await new RequestClient({
        url: `${URL_API}/posts/${post.id}`,
        method: "DELETE",
        autoSFOMAuth: true
    }).execute().then(result => {
        return true
    });
}

function convertToPost(rawData: any): PostData {
    return {
        id: rawData.id,
        title: rawData.title,
        translatedTitles: rawData.translatedTitles,
        translatedBodies: rawData.translatedBodies,
        state: rawData.state,
        authorUid: rawData.authorUid,
        basedLanguage: rawData.basedLanguage,
        boardId: rawData.boardId,
        body: JSON.parse(rawData.body),
        bodyType: rawData.bodyType,
        coverImage: rawData.coverImage,
        createdTimestamp: rawData.createdTimestamp,
        modifiedTimestamp: rawData.modifiedTimestamp,
        tags: rawData.tags ?? [],
    };
}