import {app} from "../firebase/Firebase"
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
import sha1 from "crypto-js/sha1";
import sha512 from "crypto-js/sha512";
import AdminUser from "./AdminUser";

const auth = getAuth(app);

export async function signIn(email: string, password: string) {
    if (!email.endsWith("@storeforminecraft.com")) {
    }

    let saltPhase = sha1(email).toString().substring(0, 5)
    let hashedPassword = sha512(saltPhase + sha1("MCPE STORE" + password + "ver2") + "MCPE_STORE_ver2").toString()

    return await signInWithEmailAndPassword(auth, email, hashedPassword).then((userCredential) => {
        const user = userCredential.user;
        return user
    })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            throw(error)
        });
}

export async function getIdToken() : Promise<string | undefined>{
    return await auth.currentUser?.getIdToken(true).then((idToken : string) => {
        return idToken;
    }).catch((error) => {
        return undefined;
    });
}

export function onAdminUserStateChanged(callback: (user: (AdminUser | undefined)) => void) {
    auth.onAuthStateChanged(function (user) {
        if (user) {
            if (user.email?.endsWith("storeforminecraft.com") && user.emailVerified) {
                console.log("user", user)
                callback({email: user.email, uid: user.uid} as AdminUser)
            } else {
                callback(undefined)
            }
        } else {
            callback(undefined)
        }
    });
}