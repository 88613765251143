import PostContentUnitData from "../postlib/PostContentUnitData";
import {useEffect, useState} from "react";
import PostData from "../../../data/PostData";
import PostEditorContentData from "./PostEditorContentData";
import SFOMDoc from "../postlib/SFOMDoc";
import styled from "styled-components";


const ContentContainer = styled.div`
  overflow: auto;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`

const Text = styled.div`
  overflow-x: hidden;
  width: 100%;
  font-size: 1.2rem;
  white-space: pre-wrap;
`

export default function PostTextEditor({form, body}: { form: PostEditorContentData, body: SFOMDoc }) {
    const [convertedJsonData, setConvertedJsonData] = useState("")


    useEffect(() => {
        let formObj: any = {...form}
        formObj.body = body

        setConvertedJsonData(JSON.stringify(formObj, null, 4))
    }, [form, body])

    return <ContentContainer>
        <Text>{convertedJsonData}</Text>
    </ContentContainer>
}