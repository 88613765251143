import {URL_API} from "../WebAddresses";
import {RequestClient} from "../utils/ApiClient";

export function translate(sourceLang : string, targetLang : string, text : string){
    const client = new RequestClient({
        url : URL_API + "/translation",
        method : "POST",
        body : {source : sourceLang, target : targetLang, text : text},
        parameterType : "JSON",
        autoSFOMAuth : true
    })
    return client.execute().then(result => {
        return result.data.translatedText
    })
}