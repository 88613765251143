import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCA5KNvGgCGsKhlrB8yZpzl-sVz0Opwll4",
    authDomain: "storeforminecraft.firebaseapp.com",
    databaseURL: "https://storeforminecraft.firebaseio.com",
    projectId: "storeforminecraft",
    storageBucket: "storeforminecraft.appspot.com",
    messagingSenderId: "687382802404",
    appId: "1:687382802404:web:e83e64c49b0c1de86d21f1",
    measurementId: "G-C43WJ3EXYG"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);