import PostUIEditor from "./PostUIEditor";
import "./PostEditor.css"
import PostTextEditor from "./PostTextEditor";
import {useEffect, useState} from "react";
import {UnitType} from "../postlib/PostContentUnitData";
import {useParams} from 'react-router-dom';

import PostData from "../../../data/PostData";
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fab,
    Snackbar
} from "@mui/material";

import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

import {translate} from "../../../lib/sfom/Translation";
import SFOMDoc from "../postlib/SFOMDoc";
import * as React from "react";

import * as PostRepository from "../../../lib/repositories/PostRepository";
import PostEditorFormData from "./PostEditorContentData";
import SFOMDocEditor from "./SFOMDocEditor";

export default function PostEditor() {
    const [originalPostData, setOriginalPostData] = useState<PostData | undefined>(undefined)

    const [editorFormData, setEditorFormData] = useState<PostEditorFormData>({
        title: "",
        state: "pending",
        boardId: "",
        basedLanguage: "ko",
        bodyType: "sfomdoc",
        translatedTitles: new Map(),
        translatedBodies: new Map(),
        tags: []
    })

    const [body, setBody] = useState<SFOMDoc>({
        format: "sfomdoc",
        version: 1,
        body: []
    })

    const {id} = useParams();

    const [requestDeletePost, setRequestDeletePost] = useState(false)

    const [taskAlertSnackBar, setTaskAlertSnackBar] = useState<SnackBar | undefined>(undefined)

    interface SnackBar {
        duration: number,
        severity: "success" | "error",
        message: string
    }

    useEffect(() => {
        // 포스트 ID를 지참하여 요청한 경우 포스트 정보를 가져옴. 이 때 번역 데이터는 비워 줌.
        if (id)
            PostRepository.getPost(id).then(post => {

                post.translatedBodies = new Map<string, string>()
                post.translatedTitles = new Map<string, string>()
                setEditorFormData({
                    id: post.id,
                    coverImage: post.coverImage,
                    title: post.title,
                    state: post.state,
                    boardId: post.boardId,
                    basedLanguage: post.basedLanguage,
                    bodyType: "sfomdoc",
                    translatedTitles: new Map(),
                    translatedBodies: new Map(),
                    tags: post.tags
                })
                setBody(JSON.parse(JSON.stringify(post.body)))
                setOriginalPostData(post)
            });
    }, [id])

    /**
     * 포스트 수정 및 생성 요청
     */
    const execute = async () => {
        if (!editorFormData) return;
        let targetPost = {...editorFormData};

        targetPost.translatedBodies = new Map<string, string>()
        targetPost.translatedTitles = new Map<string, string>()

        let sourceLang = targetPost.basedLanguage === "ko" ? "ko" : "en";
        let targetLang = targetPost.basedLanguage === "ko" ? "en" : "ko";

        let translatedTitle = await translate(sourceLang, targetLang, targetPost.title ?? "");
        targetPost.translatedTitles!.set(targetLang, translatedTitle);

        let translatedBody: SFOMDoc = JSON.parse(JSON.stringify(body))

        for (let unit of translatedBody.body) {
            if (!(unit.type === UnitType.HEADING1 || unit.type === UnitType.HEADING2 || unit.type === UnitType.TEXT))
                continue
            unit.data = await translate(sourceLang, targetLang, unit.data ?? "");
        }
        targetPost.translatedBodies!.set(targetLang, JSON.stringify(translatedBody))

        PostRepository.setPost({
            id: originalPostData ? originalPostData.id : "",
            title: targetPost.title,
            translatedTitles: targetPost.translatedTitles,
            translatedBodies: targetPost.translatedBodies,
            state: "published",
            authorUid: originalPostData ? originalPostData.authorUid : "",
            basedLanguage: targetPost.basedLanguage,
            boardId: targetPost.boardId,
            body: JSON.stringify(body),
            bodyType: targetPost.bodyType,
            coverImage: targetPost.coverImage,
            createdTimestamp: new Date(),
            modifiedTimestamp: new Date(),
            tags: targetPost.tags
        }, !!originalPostData).then(result => {
            setTaskAlertSnackBar({
                duration: 6000,
                message: "포스트가 업데이트 되었습니다.",
                severity: "success"
            })
        }).catch(error => {
            setTaskAlertSnackBar({
                duration: 6000,
                message: error,
                severity: "error"
            })
        });
    }

    const executeDelete = () => {
        console.log("d")
        if (!originalPostData) return
        console.log("de")
        return PostRepository.deletePost(originalPostData).then(result => {
            setTaskAlertSnackBar({
                duration: 6000,
                message: "포스트가 삭제 되었습니다.",
                severity: "success"
            })
        }).catch(error => {
            setTaskAlertSnackBar({
                duration: 6000,
                message: error,
                severity: "error"
            })
        })
    }


    return <div className={"post-editor"}>
        <div className={"left-side"}><PostUIEditor form={editorFormData} setForm={setEditorFormData} body={body}
                                                   setBody={setBody}/></div>
        <div className={"right-side"}><PostTextEditor form={editorFormData} body={body}/></div>

        <div className={"area-button-post"}>
            <Button id={"button-delete"} variant="outlined" color="error" startIcon={<DeleteIcon/>}
                    onClick={(e) => setRequestDeletePost(true)}>
                DELETE
            </Button>
            <Button id={"button-save"} variant="contained" endIcon={<SaveIcon/>} onClick={(e) => execute()}>
                SAVE
            </Button>
        </div>

        <Dialog
            open={requestDeletePost}
            /*TransitionComponent={Transition}*/
            keepMounted
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{"포스트 삭제"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {"해당 포스트를 삭제합니다. 본 작업은 문서를 완전히 제거하는 작업으로 작업 후 복구 할 수 없습니다."}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setRequestDeletePost(false)
                }}>취소</Button>
                <Button onClick={() => {
                    executeDelete()
                    setRequestDeletePost(false)
                }}>삭제</Button>
            </DialogActions>
        </Dialog>

        <Snackbar open={!!taskAlertSnackBar} autoHideDuration={taskAlertSnackBar?.duration ?? 6000} onClose={() => {
            setTaskAlertSnackBar(undefined)
        }}>
            <Alert onClose={() => setTaskAlertSnackBar(undefined)} severity="success" sx={{width: '100%'}}>
                {taskAlertSnackBar?.message ?? ""}
            </Alert>
        </Snackbar>
    </div>
}