import {getIdToken} from "../auth/SFOMAuth";
import axios from "axios";
import {URL_API} from "../WebAddresses";
import ResourceWaitData from "../../data/ResourceWaitData";
import {isResourceWaitData} from "../../data/ResourceWaitData.guard";
import {RequestClient} from "../utils/ApiClient";

export async function getResourcesWaits(): Promise<Array<ResourceWaitData>> {
    let idToken = await getIdToken();
    if (!idToken) return Promise.reject(new Error('The ID token is invalid.'))

    let waits: Array<ResourceWaitData> = await axios({
        url: URL_API + "/resources/wait",
        method: 'GET',
        headers: {
            Authorization: `Bearer ${idToken}`
        }
    }).then((response) => {
        return response.data.map((resourceWaitData: any) => {
            resourceWaitData.applicationTimestamp = new Date(Date.parse(resourceWaitData.applicationTimestamp))
            if (resourceWaitData.judgedTimestamp)
                resourceWaitData.judgedTimestamp = new Date(Date.parse(resourceWaitData.judgedTimestamp))
            if (isResourceWaitData((resourceWaitData))) return resourceWaitData
            return undefined
        });
    });

    return waits;
}

export async function getResourcesWait(id: string): Promise<ResourceWaitData | undefined> {
    let idToken = await getIdToken();
    if (!idToken) return Promise.reject(new Error('The ID token is invalid.'))

    return await axios({
        url: URL_API + `/resources/wait/${id}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${idToken}`
        }
    }).then((response) => {
        let resourceWaitData = response.data
        resourceWaitData.applicationTimestamp = new Date(Date.parse(resourceWaitData.applicationTimestamp))
        if (resourceWaitData.judgedTimestamp)
            resourceWaitData.judgedTimestamp = new Date(Date.parse(resourceWaitData.judgedTimestamp))
        if (isResourceWaitData(resourceWaitData)) return resourceWaitData
        return undefined
    });
}

export async function rejectResourceWait(id: string, reason: string): Promise<boolean> {
    const client = new RequestClient({
        url: URL_API + `/resources/wait/${id}/reject`,
        method: "POST",
        body: {reason: reason},
        parameterType: "JSON",
        autoSFOMAuth: true
    })
    return await client.execute().then(result => {
        return result.status === 200
    })
}

export async function acceptResourceWait(id: string, basedLanguage : string): Promise<boolean> {
    const client = new RequestClient({
        url: URL_API + `/resources/wait/${id}/accept`,
        method: "POST",
        body: {basedLanguage: basedLanguage},
        parameterType: "JSON",
        autoSFOMAuth: true
    })
    return await client.execute().then(result => {
        return result.status === 200
    })
}