import styled from "styled-components";
import {useNavigate, useParams} from "react-router-dom";
import {
    Autocomplete,
    Box,
    Button,
    Divider, FormControl, FormControlLabel, FormLabel,
    ImageList,
    ImageListItem,
    LinearProgress, Radio, RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import {Fragment, useEffect, useState} from "react";
import {
    acceptResourceWait,
    getResourcesWait,
    getResourcesWaits,
    rejectResourceWait
} from "../../../lib/repositories/ResourceRepository";
import ResourceWaitData from "../../../data/ResourceWaitData";
import * as React from "react";
import {isResourceWaitData} from "../../../data/ResourceWaitData.guard";
import {fileDownload} from "../../../lib/utils/FileDownload";

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 3.2rem;
  background-color: white;
  box-sizing: border-box;
  overflow: auto;
`

const CenterVertically = styled.div`
  display: flex;
  align-items: center;
`

export default function ResourcesWaitDetailPage() {
    const navigate = useNavigate();

    const options = ['맵 카테고리는 마인크래프트 맵 파일 (.mcworld) 파일이나 마인크래프트 맵 정보를 가지고 있는 ZIP 압축 파일만 업로드 가능합니다.',
        '애드온 카테고리는 마인크래프트 애드온 파일 (.mcaddon, .mcpack)이나 마인크래프트 애드온 정보를 가지고 있는 ZIP 압축 파일만 업로드 가능합니다.',
        '텍스처팩 카테고리는 마인크래프트 애드온 파일 (.mctexture, .mcpack)이나 마인크래프트 텍스처팩 정보를 가지고 있는 ZIP 압축 파일만 업로드 가능합니다.',
    '첨부한 파일이 유효한 스킨 카테고리의 자료가 아닙니다. 스킨 카테고리의 자료는 64 x 32, 64 x 64, 128 x 128 이상의 1:1 비율의 PNG 파일만 업로드 가능합니다.',
    '자료 게시글에는 자료와 관련된 충분한 설명 및 스크린샷이 포함되어야 합니다.',
    '시드 카테고리에는 유효한 시드 넘버를 기입해야합니다.',
    '더이상 모드 및 스크립트 카테고리에 새 업로드 요청을 받고 있지 않습니다. 애드온 이나 텍스처팩 등 적절한 카테고리에 다시 업로드 신청해주십시오.',
    '짧은 기간 지나치게 많은 자료를 업로드하여 업로드 요청을 거부합니다. 다음에 다시 업로드 요청해주십시오. 문의 : support@storeforminecraft.com'];

    const {id} = useParams();
    const [resourceWait, setResourceWait] = useState<ResourceWaitData>()
    const [isLoading, setIsLoading] = useState(false)


    // parameters
    const [basedLanguage, setBasedLanguage] = useState("ko")
    const [rejectReason, setRejectReason] = useState<string>("")

    useEffect(() => {
        setIsLoading(true)
        if (id) {
            getResourcesWait(id).then(r => {
                setResourceWait(r)
                setIsLoading(false)
            })
        }
    }, [id])

    function accept(basedLanguage: string) {
        if (!id) return
        setIsLoading(true)
        acceptResourceWait(id, basedLanguage).then(r => {
            if (r) {
                setIsLoading(false)
                removeFromHistory(id)
            }
        })
    }

    function reject(reason: string) {
        if (!id) return
        setIsLoading(true)
        rejectResourceWait(id, reason).then(r => {
            if (r) {
                setIsLoading(false)
                removeFromHistory(id)
            }
        })
    }

    function removeFromHistory(id: string) {
        const historyItemKey = '/resources/wait';
        let historyData = localStorage.getItem(historyItemKey);

        if (historyData) {
            let data: Array<ResourceWaitData> = JSON.parse(historyData)
            localStorage.setItem(historyItemKey, JSON.stringify(data.filter(wait => (wait.id !== id))))
        }

        navigate(-1)
    }


    return <Fragment>
        <LinearProgress sx={{
            width: '100%',
            display: isLoading ? "block" : "none"
        }}/>
        <Container>

            <CenterVertically>
                <Button variant="contained" color="success" disabled={isLoading} onClick={() => {
                    accept(basedLanguage)
                }}>
                    승인
                </Button>

                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={options}
                    inputValue={rejectReason}
                    onInputChange={(event, newInputValue) => {
                        setRejectReason(newInputValue);
                    }}
                    size={"small"}
                    sx={{width: 300, ml: 2}}
                    renderInput={(params) => <TextField {...params} label="반려 사유" color="error"

                    />}
                />

                <Button variant="outlined" sx={{ml: 1}} color="error" disabled={isLoading} onClick={
                    () => {
                        reject(rejectReason)
                    }
                }>
                    반려
                </Button>
            </CenterVertically>

            <Typography sx={{mt: 2}} variant={"body1"} gutterBottom>사용자 : {
                resourceWait?.authorProfile?.nickname
            }</Typography>

            <Typography variant={"body2"} gutterBottom>{
                resourceWait?.applicationTimestamp.toString()
            }</Typography>

            <Divider sx={{mb: 2}}/>

            <TextField fullWidth id="standard-basic-normal" margin="normal" label="Title" variant="standard"
                       size={"medium"}
                       value={resourceWait?.name ?? ""}
                /*onChange={(e) => {
                    let modified = {...resourceWait}
                    modified.name = e.target.value
                    if (isResourceWaitData(modified))
                        setResourceWait(modified)
                }}*//>


            <TextField id="standard-textarea" margin="normal" label="Category" variant="standard"
                       multiline
                       size={"medium"}
                       value={resourceWait?.category ?? ""}/>

            {(resourceWait?.tags.length ?? 0) > 0 ?
                <TextField sx={{ml: 2}} id="standard-textarea" margin="normal" label="Tags" variant="standard"
                           multiline
                           size={"medium"}
                           value={resourceWait?.tags[0]}/> : <div/>
            }


            <TextField fullWidth id="standard-textarea" margin="normal" label="Description" variant="standard" multiline
                       size={"medium"}
                       value={resourceWait?.desc ?? ""}
                       rows={7}/>

            {
                ((resourceWait?.images?.length ?? 0) > 0) ?
                    <ImageList sx={{width: '100%', height: 'auto'}} cols={5} rowHeight={164}>
                        {resourceWait?.images?.map((item) => (
                            <ImageListItem key={item}>
                                <img
                                    src={`https://image.storeforminecraft.dev/${item}`}
                                    loading="lazy"
                                />
                            </ImageListItem>
                        )) ?? []}
                    </ImageList> : <div></div>
            }

            <CenterVertically>
                <TextField id="standard-basic-normal" sx={{width: '30%'}} margin="normal" label="File Hash"
                           variant="standard" size={"medium"}
                           value={resourceWait?.fileHash ?? ""}/>

                <TextField id="standard-basic-normal" sx={{width: '20%', ml: 2}} margin="normal" label="Extension"
                           variant="standard" size={"medium"}
                           value={resourceWait?.fileExt ?? ""}/>

                <Button variant="outlined" sx={{ml: 2, mt: 1}} onClick={() => {
                    if (resourceWait?.fileHash)
                        fileDownload(resourceWait.fileHash, `${resourceWait.name}.${resourceWait.fileExt}`)
                }}>Download</Button>
            </CenterVertically>

            <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">Based Language</FormLabel>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={basedLanguage}
                    onChange={(e) => {
                        setBasedLanguage(e.target.value)
                    }}
                >
                    <FormControlLabel value="ko" control={<Radio/>} label="Korean"/>
                    <FormControlLabel value="en" control={<Radio/>} label="English"/>
                </RadioGroup>
            </FormControl>

        </Container>
    </Fragment>
}