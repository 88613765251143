import {Button, Card, CardActions, CardContent, Typography} from "@mui/material";
import ResourceWaitData from "../../../data/ResourceWaitData";

export default function ResourcesWaitListItem(props: { resourceWait: ResourceWaitData }) {
    return <Card sx={{width: '100%'}}>
        <CardContent>
                <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                    {props.resourceWait.category}
                </Typography>
                <Typography variant="h5" component="div">
                </Typography>
                <Typography sx={{mb: 1.5}} color="text.secondary">
                    {props.resourceWait.name}
                </Typography>
                <Typography variant="body2">
                    {props.resourceWait.authorProfile?.nickname}
                </Typography>
            </CardContent>
    </Card>
}