import SignInForm from "./SignInForm";
import {SFOMAuthContext, SFOMAuthContextProvider} from "../../lib/auth/SFOMAuthContext";
import {useContext} from "react";
import {Navigate} from "react-router-dom";
import styled from "styled-components";

const Box = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 500px;
  width: 768px;
  border-radius: 1.6rem;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
`

const LogoContainer = styled.div`
  position: relative;
  width: 35%;
  background-color: var(--main-bg-color);
  height: 100%;
  border-radius: 2rem 0 0 2rem;
  display: inline-block;

  .logo {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 14rem;
    display: block;
  }
`

const InputContainer = styled.div`
  position: relative;
  width: 65%;
  background-color: white;
  height: 100%;
  border-radius: 0 2rem 2rem 0;
  display: inline-block;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 25%;
  }
`


export default function SignInPage() {
    if (useContext(SFOMAuthContext)?.isSignedIn) {
        return <Navigate to={"/"} replace/>
    }

    return <Box>
        <LogoContainer>
            <img src={`${process.env.PUBLIC_URL}/public_assets/logo_sfom.png`} className='logo' alt='React'/>
        </LogoContainer>

        <InputContainer>
            <SignInForm/>
        </InputContainer>
    </Box>
}