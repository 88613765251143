import "./PostUIEditor.css"
import {
    Button,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Input,
    TextField,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import {UnitType} from "../postlib/PostContentUnitData";
import PostContentUnit from "../postlib/PostContentUnit";
import PostContentUnitMenu from "../postlib/PostContentUnitMenu";
import PostData from "../../../data/PostData";
import {ChangeEvent, RefObject, useState} from "react";
import * as React from 'react';

import {upload} from "../../../lib/sfom/Storage";
import internal from "stream";
import PostEditorContentData from "./PostEditorContentData";
import {setPost} from "../../../lib/repositories/PostRepository";
import SFOMDocEditor from "./SFOMDocEditor";
import SFOMDoc from "../postlib/SFOMDoc";

interface FileUploadRequest {
    isDialogAccept: boolean,
    file: any,
    place: string
}

export default function PostUIEditor({
                                         form,
                                         setForm,
                                         body, setBody
                                     }: {
    form: PostEditorContentData, setForm: (p: PostEditorContentData) => void,
    body: SFOMDoc, setBody: (p: SFOMDoc) => void
}) {

    const inputOpenFileRef: RefObject<HTMLInputElement> = React.createRef()

    const [fileUploadRequest, setFileUploadRequest] = useState<FileUploadRequest | undefined>(undefined)

    if (!form) return <div></div>


    const switchState = (state: string) => {
        if (!state) return;
        let copiedPost = {...form};
        copiedPost.state = state
        setForm(copiedPost)
    }

    const switchLanguage = (language: string) => {
        if (!language) return;
        let copiedPost = {...form};
        copiedPost.basedLanguage = language
        setForm(copiedPost)
    }

    const selectCoverImage = (event: any) => {
        setFileUploadRequest({
            isDialogAccept: false,
            file: event.target.files[0],
            place: "coverImage"
        })
    }

    const fileUpload = (request: FileUploadRequest) => {
        upload(request.file).then(result => {
            switch (request.place) {
                case "coverImage" :
                    let copiedPost = {...form};
                    copiedPost.coverImage = result.id
                    setForm(copiedPost)
            }
        })
    }

    return <div className={"page-ui-editor"}>
        <TextField fullWidth id="standard-basic-normal" margin="normal" label="제목" variant="standard" size={"medium"}
                   value={form.title ?? ""}
                   onChange={(e) => {
                       let copiedPost = {...form};
                       copiedPost.title = e.target.value
                       setForm(copiedPost)
                   }}/>
        <TextField fullWidth id="standard-basic-normal" margin="normal" label="게시판(보드 아이디)" variant="standard"
                   size={"medium"} value={form.boardId ?? "sfom-magazine"}
                   onChange={(e) => {
                       let copiedPost = {...form};
                       copiedPost.boardId = e.target.value
                       setForm(copiedPost)
                   }}/>

        <input type="file" ref={inputOpenFileRef} accept={"image/*"} name="coverimage" onChange={selectCoverImage}
               style={{display: "none"}}/>

        <TextField fullWidth id="standard-basic-normal" margin="normal" label="커버 이미지" variant="standard"
                   size={"medium"} value={form.coverImage ?? ""} onClick={() => inputOpenFileRef.current?.click()}/>

        <ToggleButtonGroup
            id={"post-state-select-toggle"}
            color="primary"
            exclusive
            value={form.state}
            onChange={(event, newAlignment) => {
                switchState(newAlignment)
            }}>
            <ToggleButton value="published">배포 됨</ToggleButton>
            <ToggleButton value="pending">작성 중</ToggleButton>
            <ToggleButton value="deleted">삭제 됨</ToggleButton>
        </ToggleButtonGroup>

        <ToggleButtonGroup
            id={"post-basedlang-select-toggle"}
            color="primary"
            exclusive
            value={form.basedLanguage}
            onChange={(event, newAlignment) => {
                switchLanguage(newAlignment)
            }}>
            <ToggleButton value="ko">한국어</ToggleButton>
            <ToggleButton value="en">영 어</ToggleButton>
        </ToggleButtonGroup>

        <ToggleButtonGroup
            id={"post-state-select-toggle"}
            color="primary"
            exclusive
            value={form.state}
            onChange={(event, newAlignment) => {
                switchState(newAlignment)
            }}>
        </ToggleButtonGroup>

        <SFOMDocEditor doc={body} setDoc={setBody}/>

        <Dialog
            open={(fileUploadRequest?.isDialogAccept === false)}
            /*TransitionComponent={Transition}*/
            keepMounted
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{"File Upload"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {`${fileUploadRequest?.file?.name ?? ""} 파일을 업로드 합니다.`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setFileUploadRequest(undefined)
                }}>취소</Button>
                <Button onClick={() => {
                    fileUpload(fileUploadRequest!)
                    setFileUploadRequest(undefined)
                }}>업로드</Button>
            </DialogActions>
        </Dialog>
    </div>
}