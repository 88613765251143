/*
 * Generated type guards for "ResourceWaitData.ts".
 * WARNING: Do not manually change this file.
 */
import { isProfileData } from "./ProfileData.guard";
import ResourceWaitData from "./ResourceWaitData";

function evaluate(
    isCorrect: boolean,
    varName: string,
    expected: string,
    actual: any
): boolean {
    if (!isCorrect) {
        console.error(
            `${varName} type mismatch, expected: ${expected}, found:`,
            actual
        )
    }
    return isCorrect
}

export function isResourceWaitData(obj: any, argumentName: string = "resourceWaitData"): obj is ResourceWaitData {
    return (
        (obj !== null &&
            typeof obj === "object" ||
            typeof obj === "function") &&
        evaluate(typeof obj.id === "string", `${argumentName}.id`, "string", obj.id) &&
        evaluate(typeof obj.name === "string", `${argumentName}.name`, "string", obj.name) &&
        evaluate(typeof obj.desc === "string", `${argumentName}.desc`, "string", obj.desc) &&
        evaluate(typeof obj.authorUid === "string", `${argumentName}.authorUid`, "string", obj.authorUid) &&
        evaluate(typeof obj.category === "string", `${argumentName}.category`, "string", obj.category) &&
        evaluate(Array.isArray(obj.tags) &&
            obj.tags.every((e: any) =>
                typeof e === "string"
            ), `${argumentName}.tags`, "string[]", obj.tags) &&
        evaluate(typeof obj.fileExt === "string", `${argumentName}.fileExt`, "string", obj.fileExt) &&
        evaluate(typeof obj.fileHash === "string", `${argumentName}.fileHash`, "string", obj.fileHash) &&
        evaluate(Array.isArray(obj.images) &&
            obj.images.every((e: any) =>
                typeof e === "string"
            ), `${argumentName}.images`, "string[]", obj.images) &&
        evaluate((typeof obj.reason === "undefined" ||
            obj.reason === null ||
            typeof obj.reason === "string"), `${argumentName}.reason`, "string | null | undefined", obj.reason) &&
        evaluate(typeof obj.state === "string", `${argumentName}.state`, "string", obj.state) &&
        evaluate(obj.applicationTimestamp instanceof Date, `${argumentName}.applicationTimestamp`, "Date", obj.applicationTimestamp) &&
        evaluate((typeof obj.judgedTimestamp === "undefined" ||
            obj.judgedTimestamp === null ||
            obj.judgedTimestamp instanceof Date), `${argumentName}.judgedTimestamp`, "Date | null | undefined", obj.judgedTimestamp) &&
        evaluate((typeof obj.authorProfile === "undefined" ||
            obj.authorProfile === null ||
            isProfileData(obj.authorProfile) as boolean), `${argumentName}.authorProfile`, "import(\"./src/lib/data/ProfileData\").default | null | undefined", obj.authorProfile)
    )
}
