import React, {createContext, Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import AdminUser from "./AdminUser";
import {onAdminUserStateChanged} from "./SFOMAuth";

export const SFOMAuthContext = createContext<SFOMAuthState>({isStateLoaded: false, isSignedIn: false, user: undefined});

export type SFOMAuthState = {
    isStateLoaded: boolean,
    isSignedIn: boolean,
    user: AdminUser | undefined
}

export function SFOMAuthContextProvider({children}: { children: React.ReactNode }) {
    const [currentUser, setCurrentUser] = useState<SFOMAuthState>({
        isStateLoaded: false,
        isSignedIn: false,
        user: undefined
    })

    useEffect(() => {
        onAdminUserStateChanged(function (user) {
            setCurrentUser({isStateLoaded: true, isSignedIn: !!user, user: user})
        })
    }, [])

    return <SFOMAuthContext.Provider value={currentUser}> {children} </SFOMAuthContext.Provider>
}