import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import DashBoardPage from "./dashboard/DashBoardPage";
import SignInPage from "./signin/SignInPage";
import PrivateRoute from "../route/PrivateRoute";
import {SFOMAuthContextProvider} from "../lib/auth/SFOMAuthContext";
import {useContext} from "react";
import PostListPage from "./post/page/PostListPage";
import PostUIEditor from "./post/editor/PostUIEditor";
import PostEditor from "./post/editor/PostEditor";
import ResourcesWaitListPage from "./resources/waits/ResourcesWaitListPage";
import ResourcesWaitDetailPage from "./resources/waits/ResourcesWaitDetailPage";

export default function Main() {
    return <SFOMAuthContextProvider>
        <Routes>
            <Route path="/" element={<PrivateRoute><DashBoardPage/></PrivateRoute>}>
                <Route path="posts" element={<PostListPage/>}/>
                <Route path="posts/create" element={<PostEditor/>}/>
                <Route path="posts/:id" element={<PostEditor/>}/>

                <Route path="resources/wait" element={<ResourcesWaitListPage/>}/>
                <Route path="resources/wait/:id" element={<ResourcesWaitDetailPage/>}/>
            </Route>
            <Route path="/signin" element={<SignInPage/>}/>
        </Routes>
    </SFOMAuthContextProvider>
}