import SFOMDoc from "../postlib/SFOMDoc";
import PostEditorContentData from "./PostEditorContentData";
import PostContentUnitMenu from "../postlib/PostContentUnitMenu";
import PostContentUnit from "../postlib/PostContentUnit";
import * as React from "react";
import {UnitType} from "../postlib/PostContentUnitData";

import { v4 as uuid } from 'uuid';

export default function SFOMDocEditor({doc, setDoc}: { doc: SFOMDoc, setDoc: (d: SFOMDoc) => void }) {
    const addUnit = (idx: number, type: UnitType) => {
        let copiedDoc = JSON.parse(JSON.stringify(doc));
        copiedDoc.body.splice(idx, 0, {
            type: type,
            data: ""
        })
        setDoc(JSON.parse(JSON.stringify(copiedDoc)))
    }

    const removeUnit = (idx: number) => {
        let copiedDoc = JSON.parse(JSON.stringify(doc));
        if (idx > -1) copiedDoc.body.splice(idx, 1);
        setDoc(JSON.parse(JSON.stringify(copiedDoc)))
    }

    const modify = (idx: number, text: string) => {
        let copiedDoc = JSON.parse(JSON.stringify(doc));
        copiedDoc.body[idx].data = text;
        setDoc(JSON.parse(JSON.stringify(copiedDoc)))
    }

    return <div>
        {doc.body.length}
        <PostContentUnitMenu onAddUnit={(type) => {
            addUnit(0, type)
        }} onRemoveUnit={undefined}/>
        <ul className={"list-edit-post"}>{doc.body.map((row, idx) => {
            return <li key = {uuid()}>
                <div><PostContentUnit contentUnit={row} onDataChanged={(data : string) => {
                    modify(idx, data)
                }}/>
                    <PostContentUnitMenu onAddUnit={(type) => {
                        addUnit(idx + 1, type)
                    }} onRemoveUnit={() => removeUnit(idx)}/>
                </div>
            </li>
        })}</ul>
    </div>
}