import {Fragment, useEffect, useState} from "react";
import {getPosts} from "../../../lib/repositories/PostRepository";
import PostData from "../../../data/PostData";
import {List, ListItem, ListItemButton, ListItemText, Paper, Typography} from "@mui/material";
import "./PostListPage.css"
import {useNavigate as rrdNavigate} from "react-router-dom";

export default function PostListPage() {
    let navigate = rrdNavigate();

    const [posts, setPosts] = useState<Array<PostData>>([])

    useEffect(() => {
        console.log("in")
        getPosts().then(posts => {
            setPosts(posts)
        })
    }, [])

    const linkToDetail = (id: string) => {
        navigate("/posts/" + id);
    }

    return <div className={"page-postlist"}>
        <List>
            {posts.map((post, idx) => {
                return <ListItem key={idx} component="div" disablePadding>
                    <ListItemButton onClick={() => linkToDetail(post.id!)}>
                        <ListItemText
                            primary={post.title}
                            secondary={
                                <Fragment>
                                    <Typography
                                        sx={{display: 'inline'}}
                                        component="span"
                                        variant="body2"
                                        color="text.primary">
                                        {post.state}
                                    </Typography>
                                    {` — ${new Date(post.modifiedTimestamp!).toLocaleString()}`}
                                </Fragment>
                            }
                        />
                    </ListItemButton>
                </ListItem>
            })}

        </List>
    </div>
}