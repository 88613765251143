import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import SignInPage from "./components/signin/SignInPage";
import {createTheme} from '@mui/material/styles';
import {ThemeProvider} from "@emotion/react";
import {BrowserRouter} from "react-router-dom";
import Main from "./components/Main";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const theme = createTheme({
    typography: {
        "fontFamily": `"Pretendard", "Roboto", "Helvetica", "Arial", sans-serif`,
        "fontSize": 24,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500
    },
    palette: {
        primary: {
            light: '#bdf479',
            main: '#8ac149',
            dark: '#599015',
            contrastText: '#000',
        },
        secondary: {
            light: '#6d6d6d',
            main: '#424242',
            dark: '#1b1b1b',
            contrastText: '#fff',
        },
    },
});


root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Main/>
            </BrowserRouter>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
